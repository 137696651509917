//orderdetail.js
import request from '@/utils/request'
import qs from 'qs'

// 查询订单编号和订单状态
export function ordernum (id) {
  let data = {
  id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/stafforderdetailsState',
    method: 'post',
    data
  })
}
// 追踪订单
export function ordertrace (id) {
  let data = {
  id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/stafforderdetailsTrack',
    method: 'post',
    data
  })
}
// 作品详情
export function orderarti (id) {
  let data = {
  id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/stafforderdetailsWorks',
    method: 'post',
    data
  })
}