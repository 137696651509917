
import request from '@/utils/request'
import qs from 'qs'

//稿件详情数据
// 状态，投稿编号
export function checksteps (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/stafforderdetailsState',
    method: 'post',
    data
  })
}
// 订单跟踪
export function checktrace (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/stafforderdetailsTrack',
    method: 'post',
    data
  })
}
//作品文章详情
export function checkarticle (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/stafforderdetailsWorks',
    method: 'post',
    data
  })
}